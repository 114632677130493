@charset "utf-8";

/* Laptop small S */
@media all and (min-width:1024px) and (max-width:1440px){
}
/* Laptop E */

/* Tablet and Mobile S */
@media all and (max-width:1023px){
}
/* Tablet and Mobile E */

/* Tablet S */
@media all and (min-width:768px) and (max-width:1023px){
	#tratToken .token-itr .item-img{
		width: 350px;
	}

}
/* Tablet E */

/* Mobile S */
@media all and (max-width:767px){
	/* trat token S */

	#tratToken .token-itr{
		position: relative;
	}
	#tratToken .token-itr .item-img{
		position: absolute;
		width: 100%;
	}
	#tratToken .token-itr .item-img img{
		display: inline-block;
		max-width: 260px;
		margin: auto;
	}

	#tratToken .token-itr{
		position: relative;
		justify-content: space-between;
	}
	#tratToken .token-itr .item-img{
		position: absolute;
		top: 53%;
		left: 50%;
		transform: translate(-50%, -50%);
		margin: 0;
		width: 100%;
	}
	#tratToken .token-itr .item-img img{
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		max-width: 260px;
	}
	#tratToken .token-itr .wrap-item{
		width: 40%;
		min-width: 150px;
	}
	#tratToken .token-itr .wrap-item:nth-child(1){
		text-align: start;
	}
	#tratToken .token-itr .wrap-item:nth-child(2){
		text-align: end;
	}
	#tratToken .token-itr .wrap-item .mb-d-view{
		height: 320px;
	}

	/* trat token E */
	/* block chain system S */

	#blockChainSystem .wrap-block-chain-tit .container{
		padding-bottom: 20px;
	}
	#blockChainSystem .wrap-scroll-slide{
		flex-direction: column;
		height: 200vh;
	}
	#blockChainSystem .wrap-left-slide{
		position: sticky;
		width: 100%;
		height: 50vh;
		background: var(--color-white);
		z-index: 1;
	}
	#blockChainSystem .wrap-right-slide{
		position: relative;
		width: 100%;
		height: calc(100% - 50vh);
	}
	#blockChainSystem .wrap-right-slide{
		left: 0;
	}
	#blockChainSystem .wrap-scroll-slide .left-slide-img img{
		width: 100%;
	}
	#blockChainSystem .wrap-right-slide .right-slide-txt{
		padding: 30px 24px;
	}
	#blockChainSystem .wrap-right-slide .right-slide-txt:nth-child(3){
		top: 50%;
	}

	/* block chain system E */
	/* eco system S */


	#ecoSystem .cards{
		grid-template-columns: repeat(1, 1fr);
	}
	#ecoSystem .cards .photo-card{
		height: 200px;
	}
	#ecoSystem .contents:nth-child(2) .contents-itr{
		flex-direction: column;
	}
	#ecoSystem .contents:nth-child(2) .contents-itr .item{
		flex-direction: column;
		width: 100%;
	}
	#ecoSystem .contents:nth-child(2) .contents-itr .item:nth-child(1){
		margin-bottom: 20px;
	}
	#ecoSystem .contents:nth-child(2) .contents-itr .item .left-icon{
		position: relative;
		width: 100%;
		height: 200px;
	}
	#ecoSystem .contents:nth-child(2) .contents-itr .item .left-icon img{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 120px;
	}
	#ecoSystem .contents:nth-child(2) .contents-itr .item .right-txt{
		width: 100%;
	}
	#ecoSystem .cards .photo-card .card-txt .wrap-inner-card-txt{
		top: 50%;
		transform: translate(-50%, -50%);
	}
	#ecoSystem .cards .photo-card .card-txt p:nth-child(2){
		font-size: var(--font-body1);
		letter-spacing: var(--ls-body1);
	}
	/* eco system E */
	/* trat Allocation S */

	#tratAllocation .contents{
		flex-direction: column;
	}
	#tratAllocation .contents .left-chart-img,
	#tratAllocation .contents .right-chart-txt{
		width: 100%;
	}
	/* trat Allocation E */


}
/* Mobile E */