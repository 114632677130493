header{
	position:fixed;
	top:0;
	left: 0;
	width: 100%;
	height: 60px;
	background: rgba(255, 255, 255, .2);
	z-index: 999;

	word-break: keep-all;
}
header.scroll{
	background: var(--color-white);
}
header>.container{
	display: flex;
	justify-content: space-between;
	height: 100%;
}
.header-logo{
	width: 90px;
	height: 100%;
	padding: 14px 3px;
}
.header-logo a{
	position: relative;
	display: inline-block;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.header-logo a img{
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
}
.header-logo a img:nth-child(1){
	opacity: 1;
}
.header-logo a img:nth-child(2){
	opacity: 0;
}
.scroll .header-logo a img:nth-child(1){
	opacity: 0;
}
.scroll .header-logo a img:nth-child(2){
	opacity: 1;
}

header .container .nav{
	height: 100%;
	width: auto;
}

header nav{
	display: flex;
}

header nav ul{
	height: 100%;
	display: flex;
}
header nav ul li{
	height: 100%;
	width: 120px;
	transition: all 1s;
}
header nav ul li:last-child{
	margin-right: 0;
}
header nav ul li a{
	position: relative;
	display: block;
	margin: auto;
	padding: 0 10px;
	height: 100%;
	line-height: 60px;
	color: var(--color-white);
	font-weight: 400;
	text-align: center;
}

/* member S */
header.scroll a.member {
	color: var(--color-dark);
}
header.scroll div.member::after {
	background-color: var(--color-white);
	border-top: .5px solid var(--color-dark);
	border-bottom: .5px solid var(--color-dark);
}
header nav ul li div.member {
	display: none;
	justify-content: space-between;
	text-align: center;
}
header nav ul li div.member::after {
	z-index: -1;
	display: none;
	left: 0;
	right: 0;
	width: 100vw;
	height: 100%;
	background-color: transparent;
	content: "";
	position: absolute;
	border-top: .5px solid var(--color-dark);
}
header nav ul li .member > a {
	font-size: 16px;
	position: relative;
	z-index: 2;
}
header nav ul li:hover .member,
header nav ul li:hover .member::after{
	display: flex;
}
header nav ul li .member > a:hover {
	font-weight: 700;
}
/* member E */

header nav ul li:hover a:not(.member){
	font-weight: 700;
}
header nav ul li a:not(.member)::before {
	position: absolute;
	content: "";
	left: 50%;
	bottom: 0;
	width: 0px;
	height: 2px;
	transition: 0.5s;
	transform:  translateX(-50%);
	background: var(--color-white);
}
header nav ul li:hover a:not(.member)::before{
	width: 80%;
}

header .wrap-btn-change_lang button{
	height: 50%;
	width: 48%;
	color: var(--color-white);
	border-radius: 5px;
	border: solid 1px var(--color-white);
}

header.scroll nav ul li a
{
	color: var(--color-dark);
}
header.scroll .wrap-btn-change_lang button{
	color: var(--color-dark-gray);
	border: solid 1px var(--color-dark);
}
header.scroll nav ul li:hover a::before{
	background: var(--color-dark);

}

header .wrap-btn-change_lang{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;

}

header .wrap-btn-change_lang button:disabled{
	opacity: .5;

}