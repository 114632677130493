@charset "utf-8";

/* Laptop small S */
@media all and (min-width:1024px) and (max-width:1440px){
}
/* Laptop E */

/* Tablet and Mobile S */
@media all and (max-width:1023px){
	.banner .container>.contents>img {
		width: 200px;
	}
	.banner .container>.contents>p {
		font-size: 20px;
	}
}
/* Tablet and Mobile E */

/* Tablet S */
@media all and (min-width:768px) and (max-width:1023px){

}
/* Tablet E */

/* Mobile S */
@media all and (max-width:767px){
	.banner .container>.contents>img{
		width: 30%;
		min-width: 140px;
	}
	.banner .container>.contents>p {
		font-size: 16px;
	}
	/* .banner .container>.contents>a{
		display: none;
	} */

	.banner__btn-wrap{
		width: 100%;
		top: 70px;
		right:0;
		padding: 0 20px;
	}

	.banner__btn{
		width: 49%;
		min-height: 45px;
		height: auto;
		/* width: 100px;
		height: 40px;
		line-height: 40px;
		font-size: var(--mt_font_size5);
		margin-top: 15px; */
	}

}
/* Mobile E */