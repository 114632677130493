footer{
	width: 100%;
	height: auto;
	background: var(--color-dark-gray);
	overflow: hidden;

}
footer .container{
	padding-top: 70px;
	padding-bottom: 100px;

}
footer h2
{
	margin-bottom: 20px;

	color: var(--color-medium);
	font-size: 14px;
}
footer .container>p,
footer li>p,
footer li>address,
footer small
{
	color: var(--color-gray);
	font-weight: 400;
	line-height: 24px;
	font-size: 14px;
}
footer .container > ul
{
	margin-bottom: 20px;
}
footer .container > address
{
	display: block;
	margin-bottom: 40px;

	color: var(--color-medium);
	font-size: 14px;
}

.ft-sns
{
	display: flex;
	margin-bottom: 40px;
}

.ft-sns li
{
	width: 35px;
    height: 35px;
    margin-right: 10px;
}

.ft-sns li>a
{
	display: block;
	width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #242A32;
    background-repeat: no-repeat;
    background-position: center center;
	background-size: 50%;
}

.ft-sns li:nth-child(1)>a
{
	background-image: url(../img/ico_ft_facebook.png);
	background-size: 35%;
}
.ft-sns li:nth-child(2)>a
{
	background-image: url(../img/ico_ft_naver.png);
}
.ft-sns li:nth-child(3)>a
{
	background-image: url(../img/ico_ft_instagram.png);
}
