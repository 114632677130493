@charset "utf-8";

/* Laptop small S */
@media all and (min-width:1024px) and (max-width:1440px){
}
/* Laptop E */

/* Tablet and Mobile S */
@media all and (max-width:1023px){
}
/* Tablet and Mobile E */

/* Tablet S */
@media all and (min-width:768px) and (max-width:1023px){

}
/* Tablet E */

/* Mobile S */
@media all and (max-width:767px){
	#roadMap .container{
		padding-top: 120px;
		padding-bottom: 120px;
	}
	#roadMap h1{
		margin-bottom: 120px;
	}
	#roadMap .contents .years>li{
		margin-bottom: 80px;
	}
}
/* Mobile E */