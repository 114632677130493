#about{
	padding-top: var(--p-2);

	text-align: center;
}

#about h2{
	color: var(--color-dark-blue);
	font-size: var(--font-h3);
	font-weight: 700;
}
#about .container {
	margin-bottom: var(--p-4);
}

#about>.container:nth-child(1) h3{
	margin-bottom: 60px;

	text-align: center;
}
#about>.container:nth-child(1) p{
	width: 100%;
	margin: auto;
	margin-bottom: var(--p-2);

	font-size: var(--font-h3);
	letter-spacing: var(--ls-h3);
}
#about>.container:nth-child(1) .contents{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#about>.container:nth-child(1) .contents div:nth-child(1),
#about>.container:nth-child(1) .contents div:nth-child(3){
	position: relative;
	width: 340px;
	height: 200px;
	background: var(--color-shine);
	box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.13);
	border-radius: 30px;
}
#about>.container:nth-child(1) .contents div p{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 60%;
	transform: translate(-50%, -50%);

	font-weight: 700;
}

#about>.container:nth-child(1) .contents .box-x{
	position: relative;
	width: 65px;
	height: 65px;
	overflow: hidden;
}
#about>.container:nth-child(1) .contents .box-x::before{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 200%;
	height: 1px;
	background: var(--color-dark);
	transform: rotate(45deg);
	transform-origin: 0 0;
	
}

#about>.container:nth-child(1) .contents .box-x::after{
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 200%;
	height: 1px;
	background: var(--color-dark);
	transform: rotate(-45deg);
	transform-origin: 100% 100%;
}


/* GLOVEIN feature S */
.wrap-features-container{
	position: relative;
	width: 100%;
	padding-bottom: 1px;

}
.wrap-features-container::after{
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	height: 50%;
	width: 100%;
	background: var(--color-light);
	z-index: -1;
}
#traFeatures{
	position: relative;
}

#traFeatures > p{
	margin-top: 15px;
	margin-bottom: var(--p-1);

	text-align: start;
	font-size: var(--font-sub2);
	letter-spacing: var(--ls-sub2);
	font-weight: 700;
}
#traFeatures > ul{
	display: flex;
	justify-content: space-between;
	height: 400px;
}
#traFeatures >ul>li{
	width: 25%;
	box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.2);
	border-radius: 15px;
	padding: 120px 40px 100px 40px;
	margin-right: 2%;
	background: var(--color-white);
}
#traFeatures>ul>li:nth-last-child(1){
	margin-right: 0;
}
#traFeatures>ul>li>span{
	display: inline-block;
	width: 80px;
	height: 80px;
	margin-bottom: 47px;
}

#traFeatures>ul>li:nth-child(1)>span{
	background-image: url('../../img/icons_re.png');
	background-repeat: no-repeat;
}
#traFeatures>ul>li:nth-child(2)>span{
	background-image: url('../../img/icons_re.png');
	background-repeat: no-repeat;
	background-position-x: -80px;
}
#traFeatures>ul>li:nth-child(3)>span{
	background-image: url('../../img/icons_re.png');
	background-repeat: no-repeat;
	background-position-x: -160px;
}
#traFeatures>ul>li:nth-child(4)>span{
	background-image: url('../../img/icons_re.png');
	background-repeat: no-repeat;
	background-position-x: -240px;
}
#traFeatures >ul>li>p{
	font-size: var(--font-body1);
	letter-spacing: var(--ls-body1);
	font-weight: 700;
}

/* tra feature E */
/* tra Wallet S */
.wrap-wallet-container{
	position: relative;
	width: 100%;
	background: var(--color-light);
	padding-bottom: 1px;
}
#traWallet{
	position: relative;
}
#traWallet > p{
	font-weight: 700;
	font-size: var(--font-h3);
	text-align: start;
	color: var(--color-dark-blue);
}
#traWallet > h3{
	margin-bottom: var(--p-1);
}
#traWallet .wallet-card{
	display: flex;
	padding: 50px 40px;
	width: 100%;
	background: var(--color-white);
}
#traWallet .wallet-card .contents{
	padding: 40px 80px 40px 0;
	text-align: left;
}
#traWallet .wallet-card .contents>h4{
	margin-bottom: 40px;

	font-size: var(--font-h3);
	letter-spacing: var(--ls-h3);
	font-weight: 700;
}
#traWallet .wallet-card .contents p{
	font-size: var(--font-body1);
	letter-spacing: var(--ls-body1);
	color: var(--color-dark-gray);
}
#traWallet .wallet-card>img{
	width: 40%;
}

/* tra Wallet E */
/* tra vision S */

.wrap-vision-container{
	width: 100%;
	background-image: url(../../img/bg_trat_allocation.jpg);
	background-size: cover;
}
#traVision.container{
	padding-top: var(--p-2);
	padding-bottom: var(--p-2);
	margin-bottom: 0;

}
#traVision * {
	color: var(--color-white);
}
#traVision>p{
	font-size: var(--font-h3);
	letter-spacing: var(--ls-h3);
	font-weight: 700;
	text-align: start;
	color: var(--color-white);
}
#traVision>h3{
	margin-bottom: var(--p-1);
}
#traVision>ol{
	display: flex;
	justify-content: space-around;
}
#traVision>ol>li{
	width: 25%;
	height: 200px;
	padding: 20px 3%;
	margin-right: 2%;
	border: solid 1px var(--color-white);
	border-radius: 15px;
	background:rgba(255, 255, 255, 0.2);

}

#traVision>ol>li:nth-last-child(1){
	margin-right: 0;
}
#traVision>ol>li>p:nth-child(1){
	display: inline-block;
	border-bottom: 1px solid var(--color-white);
	margin-bottom: 30px;

	font-weight: 700;
	font-size: var(--font-h3);
	letter-spacing: var(--ls-h3);

}
#traVision>ol>li>p{
	font-size: var(--font-body1);
	letter-spacing: var(--ls-body1);
	font-weight: 500;
}
/* tra vision E */

/* blockChainSystem S */

#blockChainSystem{
	text-align: start;
}

#blockChainSystem .wrap-block-chain-tit{
	width: 100%;
	background: var(--color-white);
}
#blockChainSystem .wrap-block-chain-tit .container{
	padding-top: var(--p-2);
	padding-bottom: 40px;
	margin-bottom: 0px;
}
#blockChainSystem .wrap-block-chain-tit h3{
	margin-bottom: 15px;

	color: var(--color-dark);
}
#blockChainSystem .wrap-block-chain-tit p{

	font-size: var(--font-body2);
	letter-spacing: var(--ls-body2);
	color: var(--color-dark);
}
#blockChainSystem .wrap-scroll-slide{
	display: flex;
	height: 200vh;
	width: 100%;
}
#blockChainSystem .wrap-left-slide{
	position: sticky;
	top: 0;
	left: 0;
	height: 50%;
	width: 50%;
}
#blockChainSystem .wrap-scroll-slide .left-slide-img{
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;

	transition: all .5s;
	background: linear-gradient(to bottom, var(--color-white), var(--color-light))
}
#blockChainSystem .wrap-scroll-slide .left-slide-img:nth-child(2){
	opacity: 0;
	transition: all .5s;
}
#blockChainSystem .wrap-scroll-slide .left-slide-img img{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 830px;
	width: 100%;
	max-height: 95%;
}
#blockChainSystem .wrap-right-slide{
	position: relative;
	width: 50%;
	height: 100%;
	background-color: var(--color-shine);
}
#blockChainSystem .wrap-right-slide>img{
	position: sticky;
	top: 47vh;
	left: 100%;
	height: 50vh;
}

#blockChainSystem .wrap-right-slide .right-slide-txt{
	position: absolute;
	top:0;
	left:0;
	height: 50%;
	padding: 50% 20%;
}
#blockChainSystem .wrap-right-slide .right-slide-txt:nth-child(3){
	top:100vh;
}

#blockChainSystem .right-slide-txt h3{
	position: relative;
	display: inline-block;
	width: auto;
	margin-bottom: 40px;

}
#blockChainSystem .right-slide-txt h3::after{
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: linear-gradient(to right, #fff, #645732);
}
#blockChainSystem .right-slide-txt p:nth-child(2){
	font-size: var(--font-body1);
	letter-spacing: var(--ls-body1);
}
#blockChainSystem .right-slide-txt p:nth-child(4){
	font-size: var(--font-body2);
	letter-spacing: var(--ls-body2);
	color: var(--color-gray);
}

/* blockChainSystem E */


