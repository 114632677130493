@charset "utf-8";

/* Laptop small S */
@media all and (min-width:1024px) and (max-width:1440px){
}
/* Laptop E */

/* Tablet and Mobile S */
@media all and (max-width:1023px){

	#team .person-itr.reverse .person-txt,
	#advisor .person-itr.reverse .person-txt
	{
		width: 300px;
		flex-shrink: 0;
	}
	#team .person-itr .person-txt,
	#advisor .person-itr .person-txt {
		width: 100%;
	}
	#team .person-itr.reverse .person-txt ul li,
	#advisor .person-itr.reverse .person-txt ul li
	{
		width: 100%;
	}
	#team .person-itr .person-img,
	#advisor .person-itr .person-img
	{
		width: 300px;
	}

}
/* Tablet and Mobile E */

/* Tablet S */
@media all and (min-width:768px) and (max-width:1023px){
	#team .person-itr,
	#advisor .person-itr
	{
		width: 100%;
	}
	#team .person-itr .person-img,
	#advisor .person-itr .person-img {
		margin-right: 50px;
	}
	#team .person-itr .person-img img,
	#advisor .person-itr .person-img img {
		width: 250px;
	}
}
/* Tablet E */

/* Mobile S */
@media all and (max-width:767px){
	#team .container,
	#advisor .container
	{
		padding-top: 140px;
		padding-bottom: 140px;
	}

	#team .container .contents h1,
	#advisor .container .contents h1
	{
		margin-bottom: 80px;

		font-size: 24px;
		font-weight: 400;
		line-height: 24px;
	}

	.profile-item{
		flex-direction: column;
	}
	.profile-item__content{
		min-width: 320;
		max-width: 270px;
		width: 100%;
	}
	.profile-item__img{
		width: 100%;
		max-width: 370px;
		height: auto;
		max-height: 370px;
	}
}
/* Mobile E */