@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&family=Roboto:wght@300;400;500;700&display=swap');

:root{
	--font-h1: 36px;
	--font-h2: 28px;
	--font-h3: 20px;
	--font-sub1: 16px;
	--font-sub2: 14px;
	--font-body1: 16px;
	--font-body2: 14px;
	--ls-h1: 0.25px; 
	--ls-h2: 0px;
	--ls-h3: 0.15px;
	--ls-sub1: 0.15px;
	--ls-sub2: 0.1px;
	--ls-body1: 0.5px;
	--ls-body2: 0.25px;

	--color-brown: #6c571b;
	--color-dark-blue: #072E66;
	--color-light-blue: #4677B8;
	--color-white: #fff;
	--color-dark: #1E1E1E;
	--color-dark-gray: #4B4B57;
	--color-gray: #88929E;
	--color-medium: #BDC5CA;
	--color-light: #DCDEE7;
	--color-shine: #FAFAFC;
	--color-gradient: linear-gradient(264.13deg, #645732 11.15%, #072E66 90.19%);

	/* --p-1: 60px; */
	--p-1: 60px;
	--p-2: 120px;
	--p-3: 140px;
	--p-4: 180px;
	--p-5: 240px;

	
}

*{
	box-sizing: border-box;
	color: var(--color-dark);
	font-weight: 500;
}

html,
body{
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;

	word-break: keep-all;
	font-weight: 500;
}

:lang(en){
	font-family: 'Roboto',sans-serif;
}
:lang(ko){
	font-family: 'Noto Sans KR', sans-serif;
}
:lang(zh-CN){
	word-break: break-all;
}
.container {
	width: 1140px;
	margin: auto;
}
.blind{
    position: absolute;/*레이아웃에 영향을 없앰*/
    clip: rect(0 0 0 0);/*눈에 보이는 부분을 제거*/
    width: 1px;/*스크린 리더가 읽을 수 있도록*/
    height: 1px;/*스크린 리더가 읽을 수 있도록*/
    margin: -1px;/*눈에 보이는 부분을 제거*/
    overflow: hidden;/*눈에 보이는 부분을 제거*/
}
.d-none{
	display: none !important;
}
a{
	text-decoration: none;
}
img{
	vertical-align: top;
}

.wrap{
	position: relative;
}

button#toTop{
	position: fixed;
	bottom: 100px;
	right: 100px;
	width: 60px;
	height: 60px;
	background: rgba(0,0, 0, .3);
	backdrop-filter: blur(5px);
	border-radius: 100%;

	color: var(--color-white);
	font-size: var(--font-sub1);
	font-weight: 700;

	opacity: 0;
	transition: all .5s;
	visibility: hidden;

}

.container {
	padding: 0 32px;
	margin: auto;
	width: 100%;
	max-width: 1126px;
}
.txt {
	margin-bottom: 60px;
}


/* 위로 올라가는 버튼 */

button#toTop.to-top-open{
	opacity: 1;
	visibility: visible;
	box-shadow: 1px 1px 4px rgba(0,0, 0, .3);

}
button#toTop.to-top-open:hover{
	background: var(--color-white);
	color: var(--color-dark);
}

/* 위로 올라가는 버튼 */


h3{
	font-size: var(--font-h1);
	letter-spacing: var(--ls-h3);
	text-align: left;
}

/* display none S */
.mb-d-view{
	display: none;
}
/* display none E */


/* CN-zh S */
html[lang='en'] .cn-view,
html[lang='ko'] .cn-view,
html[lang='en'] .cn-block,
html[lang='ko'] .cn-block,
html[lang='en'] .cn-inline,
html[lang='ko'] .cn-inline,
html[lang='en'] .cn-inline-block,
html[lang='ko'] .cn-inline-block,
html[lang='en'] .cn-flex
html[lang='ko'] .cn-flex
{
	display: none !important;
}
html[lang='zh-CN'] .cn-block{
	display: block !important;
}
html[lang='zh-CN'] .cn-inline{
	display: inline;
}
html[lang='zh-CN'] .cn-inline-block{
	display: inline-block;
}
html[lang='zh-CN'] .cn-flex{
	display: flex;
}
html[lang='zh-CN'] .cn-none{
	display: none !important;
}
/* CN-zh E */
/* en S */
html[lang='en'] .en-none
{
	display: none !important;
}
/* en E */
/* ko S */
html[lang='ko'] .ko-none
{
	display: none !important;
}
/* ko E */
