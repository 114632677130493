@charset "utf-8";

/* Laptop small S */
@media all and (min-width:1024px) and (max-width:1440px){
}
/* Laptop E */

/* Tablet and Mobile S */
@media all and (max-width:1023px){
}
/* Tablet and Mobile E */

/* Tablet S */
@media all and (min-width:768px) and (max-width:1023px){

}
/* Tablet E */

/* Mobile S */
@media all and (max-width:767px){
	header nav ul li div.member {
		display: none;
		height: 0px;
	}
	header nav ul li div.member > a {
		text-align: center;
		display: block !important;
	}

	header nav ul li:hover div.member{
		display: none;
	}
	header nav ul li:hover a:not(.member){
		font-weight: 400;
	}
	header nav ul li:hover a::before,
	header[data-vi=true] nav ul li:hover a::before{
		background: var(--color-white) !important;
	}
	header nav ul li a.show:not(.member)::before{
		width: 80% !important;
	}
	header nav ul li a.show::before,
	header[data-vi=true] nav ul li a.show::before{
		background: var(--color-dark) !important;
	}
	header nav ul li a.show+div.member{
		display: block;
		border-top: .5px solid black;
	}
	header nav ul li a.show+.member::after {
		display: none;
	}

	header{
		height: 50px;
	}
	header[data-vi='true'] {
		background:var(--color-white);
	}
	header>.container{
		align-items: center;
	}
	.header-logo{
		padding: 9px 3px;
	}
	header .hamberger{
		position: relative;
		width: 18px;
		height: 16px;
		overflow: hidden;
	}
	header .hamberger .line{
		position: absolute;
		left: 0;
		width: 200%;
		height: 3px;
		background: var(--color-white);
	}
	header[data-vi='true'] .hamberger .line{
		background: var(--color-dark);
	}
	.scroll .hamberger .line{
		background: var(--color-dark);
	}
	header .hamberger .line-1{
		transform-origin: 2px 0;
		top:0;
		transition: .3s;
	}
	header .hamberger .line-2{
		top:50%;
		transform: translateY(-50%);
		transition: .3s;
	}
	header .hamberger .line-3{
		transform-origin: 2px 100%;
		bottom:0;
		transition: .3s;
	}

	header .opened .line-1{
		transform: rotate(40deg);
		transition: .3s;

	}
	header .opened .line-2{
		opacity: 0;
		transition: .3s;
	}
	header .opened .line-3{
		transform: rotate(-40deg);
		transition: .3s;

	}

	#gnb{
		flex-direction: column;
		position: absolute;
		top:50px;
		left: 0;
		width: 100%;
		height: 0;
		overflow: hidden;
		background: var(--color-white);
		z-index: -1;
		transition: all .5s;
	}
	.open-nav #gnb{
		height: calc(100vh);
	}
	#gnb>ul{
		opacity: 0;
	}
	#gnb>ul{
		height: auto;
		width: 100%;
		flex-direction: column;
		padding-top: 50px;
		padding-right: 24px;
		padding-left: 24px;
		background: var(--color-white);
		opacity: 1;

	}
	header nav ul li{
		width: 100%;
		height: auto;
		margin-right: 0;
		border-bottom: 1px solid var(--color-dark);
		transition: none;
	}
	header nav ul li a{
		width: 100%;
		padding: 23px 0;
		background: var(--color-white);

		line-height: 16px;
		color: var(--color-dark);
	}
	header .wrap-btn-change_lang{
		height: 50px;
		padding-right: 24px;
		padding-left: 24px;
		justify-content: right;
	}
	header .wrap-btn-change_lang button{
		border: solid 1px var(--color-dark);
		color: var(--color-dark);
		margin-left: 20px;
		width: auto;
		height: auto;
		padding: 5px;
	}

}
/* Mobile E */