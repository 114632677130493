
/* tratToken S */
.wrap-token-container{
	width: 100%;
	background: #000;
}
#tratToken.container{
	padding-top: var(--p-2);
	padding-bottom: var(--p-2);
}
#tratToken.container *{
	color: var(--color-white);
}
#tratToken h3{
	margin-bottom: var(--p-3);
}
#tratToken .token-itr{
	display: flex;
	justify-content: center;
}
#tratToken .token-itr .wrap-item{
	display: flex;
	flex-direction: column;
	width: 30%;
	justify-content: space-between;
}
#tratToken .token-itr .wrap-item:nth-child(1){
	text-align: end;
}

#tratToken .token-itr .item-img{
	margin: 0 3%;
	flex-shrink: 0;
	width: 430px;
}
#tratToken .token-itr .item-img img{
	width: 100%;
}
#tratToken .token-itr .wrap-item .item{
	width: 100%;
	height: auto;
}
#tratToken .token-itr .wrap-item .item:nth-last-child(1){
	padding-bottom: 10%;

}


#tratToken .token-itr .wrap-item .item h4{
	font-weight: 700;
	color: var(--color-light-blue);
	font-size: var(--font-h3);
	letter-spacing: var(--ls-h3);
	margin-bottom: 7px;

}

#tratToken .token-itr .wrap-item .item p{
	display: inline-block;
	width: 90%;
	font-size: var(--font-body2);
	letter-spacing: var(--ls-body2);
}

/* tratToken E */
/* blockChainSystem S */
/* 
#blockChainSystem .wrap-block-chain-tit{
	width: 100%;
	background: #000;
}
#blockChainSystem .wrap-block-chain-tit .container{
	padding-top: var(--p-2);
	padding-bottom: 40px;
}
#blockChainSystem .wrap-block-chain-tit h3{
	margin-bottom: 15px;

	color: var(--color-white);
}
#blockChainSystem .wrap-block-chain-tit p{

	font-size: var(--font-body2);
	letter-spacing: var(--ls-body2);
	color: var(--color-white);
}
#blockChainSystem .wrap-scroll-slide{
	display: flex;
	height: 200vh;
	width: 100%;
}
#blockChainSystem .wrap-left-slide{
	position: sticky;
	top: 0;
	left: 0;
	height: 50%;
	width: 50%;
}
#blockChainSystem .wrap-scroll-slide .left-slide-img{
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;

	transition: all .5s;
}
#blockChainSystem .wrap-scroll-slide .left-slide-img:nth-child(2){
	opacity: 0;
	transition: all .5s;
}
#blockChainSystem .wrap-scroll-slide .left-slide-img img{
	position: absolute;
	top:50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 90%;
}
#blockChainSystem .wrap-right-slide{
	position: relative;
	width: 50%;
	height: 100%;
	background-color: var(--color-dark-blue);
}
#blockChainSystem .wrap-right-slide>img{
	position: sticky;
	top: 47vh;
	left: 100%;
	height: 50vh;
}

#blockChainSystem .wrap-right-slide .right-slide-txt{
	position: absolute;
	top:0;
	left:0;
	height: 50%;
	padding: 30% 20%;
}
#blockChainSystem .wrap-right-slide .right-slide-txt:nth-child(3){
	top:100vh;
}

#blockChainSystem .right-slide-txt h3{
	position: relative;
	display: inline-block;
	width: auto;
	margin-bottom: 40px;

	color: var(--color-white);
}
#blockChainSystem .right-slide-txt h3::after{
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: linear-gradient(to right, #fff, #645732);
}
#blockChainSystem .right-slide-txt p:nth-child(2){
	font-size: var(--font-body1);
	letter-spacing: var(--ls-body1);
	color: var(--color-white);
}
#blockChainSystem .right-slide-txt p:nth-child(4){
	font-size: var(--font-body2);
	letter-spacing: var(--ls-body2);
	color: var(--color-medium);
} */

/* blockChainSystem E */
/* ecoSystem S */

#ecoSystem.container{
	padding-top: var(--p-2);
	padding-bottom: var(--p-2);
}
#ecoSystem h3{
	margin-bottom: 10px;
}
#ecoSystem .contents:nth-child(1){
	margin-bottom: var(--p-3);
}
#ecoSystem .contents:nth-child(1) > p{
	margin-bottom: var(--p-1);

	color: var(--color-gray);
}
#ecoSystem .cards{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
}
#ecoSystem .cards .photo-card{
	position: relative;
	height: calc((100vw - 64px) * 0.4);
	max-height: 400px;
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.07);
	border-radius: 15px;
	background-position: 20% 20%;
	background-size: 105% 105%;
	overflow: hidden;
}
#ecoSystem .cards .photo-card img{
	position: absolute;
	top:50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 108%;
	min-height: 100%;

}
#ecoSystem .cards .photo-card .cover{
	position: absolute;
	top:0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}

#ecoSystem .cards .photo-card .card-txt{
	position: relative;
	height: 100%;
	width: 100%;
}

#ecoSystem .cards .photo-card .card-txt .wrap-inner-card-txt{
	position: absolute;
	top: calc(60% - (400px - 100%) );
	left: 50%;
	transform: translateX(-50%);
	width: 90%;
	height: auto;

}
#ecoSystem .cards .photo-card .card-txt p{
	color: var(--color-white);
}

#ecoSystem .cards .photo-card .card-txt p:nth-child(1){
	font-size: var(--font-h3);
	letter-spacing: var(--ls-h3);
	margin-bottom: 10px;
	font-weight: 700;
}
#ecoSystem .cards .photo-card .card-txt p:nth-child(2){
	font-size: var(--font-body2);
	letter-spacing: var(--ls-body2);
}
#ecoSystem .contents:nth-child(2) h3{
	margin-bottom: var(--p-1);
}
#ecoSystem .contents:nth-child(2) .contents-itr{
	display: flex;
}

#ecoSystem .contents:nth-child(2) .contents-itr .item{
	width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#ecoSystem .contents:nth-child(2) .contents-itr .item .left-icon{
	/* position: relative; */
	width: 40%;
    padding-bottom: 30px;
}
#ecoSystem .contents:nth-child(2) .contents-itr .item .left-icon img{
	top: 0;
	left: 50%;
	width: 100%;
}
#ecoSystem .contents:nth-child(2) .contents-itr .item .right-txt{
	width: 90%;
	color: var(--color-dark-blue);
}
#ecoSystem .contents:nth-child(2) .contents-itr .item .right-txt h4{
	margin-bottom: 20px;

	font-weight: 700;
	font-size: var(--font-h2);
	letter-spacing: var(--ls-h2);
	color: var(--color-dark-blue);
}
#ecoSystem .contents:nth-child(2) .contents-itr .item .right-txt p{
	font-size: var(--font-body2);
	letter-spacing: var(--ls-body2);
}

/* ecoSystem E */
/* tratAllocation S */
.wrap-allocation-container{
	width: 100%;
	background: #000;
}

#tratAllocation.container{
	padding-top: var(--p-2);
	padding-bottom: var(--p-2);
}
#tratAllocation h3{
	margin-bottom: var(--p-2);

	text-align: center;
	color: #fff;
}
#tratAllocation .contents{
	display: flex;
	align-items: center;
}
#tratAllocation .contents .left-chart-img{
	width: 95%;
	overflow: hidden;
}
#tratAllocation .contents .left-chart-img img{
	display: block;
	margin: auto;
	width: 95%;


}
#tratAllocation .contents .right-chart-txt{
	width: 45%;
}
#tratAllocation .contents .right-chart-txt ul{
	/* display: flex;
	flex-direction: column; */
	width: 100%;

}
#tratAllocation .contents .right-chart-txt ul li{
	position: relative;
	display: flex;
	border-bottom: 1px solid var(--color-medium);
	padding: 30px 0 30px 50px;
}
#tratAllocation .contents .right-chart-txt ul li::before{
	content: '';
	position: absolute;
	left: 2px;
	top: 50%;
	transform: translateY(-50%);
	width: 21px;
	height: 21px;
	border-radius:4px;
}
#tratAllocation .contents .right-chart-txt ul li:nth-child(1)::before{
	background: #9DD2F7;
}
#tratAllocation .contents .right-chart-txt ul li:nth-child(2)::before{
	background: #346DFF;
}
#tratAllocation .contents .right-chart-txt ul li:nth-child(3)::before{
	background: #00FFF0;
}
#tratAllocation .contents .right-chart-txt ul li:nth-child(4)::before{
	background: #243D7B;
}
#tratAllocation .contents .right-chart-txt ul li:nth-child(5)::before{
	background: #001EA4;
}
#tratAllocation .contents .right-chart-txt ul li:nth-child(6)::before{
	background: #0047BB;
}
#tratAllocation .contents .right-chart-txt ul li:nth-child(7)::before{
	background: #B1B3B5;
}
#tratAllocation .contents .right-chart-txt ul li:nth-last-child(1){
	border-bottom: none;
}

#tratAllocation .contents .right-chart-txt ul li p{
	width: 80%;

	font-weight: 400;
	color: var(--color-white);
	font-size: var(--font-body1);
	letter-spacing: var(--ls-body1);
}
#tratAllocation .contents .right-chart-txt ul li span{
	color: var(--color-white);
	font-size: var(--font-h3);
	font-weight: 700;
	letter-spacing: var(--ls-h3);
}
/* tratAllocation E */