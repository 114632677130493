@charset "utf-8";

/* Laptop small S */
@media all and (min-width:1024px) and (max-width:1440px){
    /* .container{
        width: 980px;
    } */
}
/* Laptop E */

/* Tablet and Mobile S */
@media all and (max-width:1023px){
    /* .wrap .pc_view{
        display: none;
    }
    .mt_d_view{
        display: block;
    }
    .container{
        padding-top: 80px;
        padding-bottom: 80px;
    } */
}
/* Tablet and Mobile E */

/* Tablet S */
@media all and (min-width:768px) and (max-width:1023px){
    /* .tablet_view{
        display: block;
    }
    .container{
        width: 700px;
        margin-left: auto;
        margin-right: auto;
    } */

}
/* Tablet E */

/* Mobile S */
@media all and (max-width:767px){

    :root{
        --font-h1: 28px;
        --font-h2: 16px;
        --font-h3: 16px;
        --font-body1: 14px;
        --font-body2: 12px;
        --ls-h1: 0px; 
        --ls-h2: 0.15px;
        --ls-h3: 0.1px;

        --p-1: 30px;
        --p-2: 60px;
        --p-3: 70px;
        --p-4: 90px;

    }
    .container{
        padding-right: 20px;
        padding-left: 20px;
    }

    .mb-d-view{
        display: block;
    }
}
/* Mobile E */