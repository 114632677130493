@charset "utf-8";

/* Laptop small S */
@media all and (min-width:1024px) and (max-width:1440px){
}
/* Laptop E */

/* Tablet and Mobile S */
@media all and (max-width:1023px){
}
/* Tablet and Mobile E */

/* Tablet S */
@media all and (min-width:768px) and (max-width:1023px){
	#about>.container:nth-child(1) .contents div:nth-child(1), #about>.container:nth-child(1) .contents div:nth-child(3){
		height: 180px;
		width: 280px;
	}

	#traFeatures >ul>li{
		padding-top: 10%;
	}
	#traWallet .wallet-card{
		align-items: center;
	}
	#traWallet .wallet-card>img{
		height: 100%;
		/* margin-bottom: calc(90%); */
	}
	#traVision>ol>li>p:nth-child(1){
		margin-bottom: 10px;
	}
}
/* Tablet E */

/* Mobile S */
@media all and (max-width:767px){
	#about>.container:nth-child(1) h3{
		margin-bottom: 20px;
	}
	#about>.container:nth-child(1) .contents{
		flex-direction: column;
	}
	#about>.container:nth-child(1) .contents div{
		margin-bottom: 30px;
	}
	#about>.container:nth-child(1) .contents div:nth-child(1),
	#about>.container:nth-child(1) .contents div:nth-child(3){
		width: 90%;
		max-width: 220px;
		height: 120px;
		border-radius: 15px;
	}
	#about>.container:nth-child(1) .contents .box-x{
		width: 30px;
		height: 30px;
	}

	/* trat feature S */
	#traFeatures > ul{
		flex-direction: column;
		height: auto;
		width: 100%;
	}
	#traFeatures >ul>li {
		display: flex;
		justify-content: start;
		align-items: center;
		width: 100%;
		padding: 20px 30px;
		margin-bottom: 10px;
		box-shadow:  1px 1px 5px rgba(0, 0, 0, .1);
		border-radius: 5px;
	}
	#traFeatures>ul>li>span{
		flex-shrink: 0;
		width: 50px;
		height: 50px;
		margin-bottom: 0;
		margin-right: 30px;
		background-size: 400%;
	}
	#traFeatures>ul>li:nth-child(2)>span{
		background-position-x: -50px;
	}
	#traFeatures>ul>li:nth-child(3)>span{
		background-position-x: -100px;
	}
	#traFeatures>ul>li:nth-child(4)>span{
		background-position-x: -150px;
	}
	#traFeatures >ul>li>p{
		text-align: start;
	}

	/* trat feature E */
	/* trat wallet S */

	#traWallet .wallet-card{
		flex-direction: column-reverse;
		align-items: center;
		padding: 14px 12px;
	}
	#traWallet .wallet-card>img{
		width: 100%;
		max-width: 400px;
	}
	#traWallet .wallet-card .contents{
		padding: 27px 0;
	}

	/* trat wallet E */
	/* tra vision S */

	#traVision>ol{
		flex-direction: column;
		border-radius: 5px;
		border: solid 1px var(--color-white);
	}
	#traVision>ol>li{
		display: flex;
		align-items: center;

		width: 100%;
		height: auto;
		border-radius: 0;
		border: none;
		border-bottom: 1px solid var(--color-white);
		padding: 15px;

		text-align: start;
	}
	#traVision>ol>li:nth-last-child(1){
		border-bottom: none;
	}
	#traVision>ol>li>p:nth-child(1){
		margin-bottom: 0;
		border-bottom: none;
		border-right: 1px solid var(--color-white);
		padding: 14px;
		margin-right: 14px;
	}

	/* tra vision E */

}
/* Mobile E */