#team,
#advisor
{
	width: 100%;
}

#team .container,
#advisor .container
{
	padding-top: 155px;
	padding-bottom: 150px;
}

.team__title{
	margin-bottom: 190px;

	font-size: 36px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.2px;
}
.team__profile-list{
	width: 100%;
}
.team__profile-item{
	width: 100%;
	margin-bottom: 60px
}
.team__profile-list .team__profile-item:last-child{
	margin-bottom: 0;
}

.profile-item{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.profile-item__img{
	width: 370px;
	height: 370px;
}
.profile-item__img img{
	width: 100%;
	height: 100%;
}
.profile-item__content{
	width: 50%;
}
.profile-item__name{
	border-bottom: 1px solid;
	padding-bottom: 6px;
	margin-bottom: 6px;

	line-height: 24px;
	letter-spacing: 0.2px;
}
.profile-item__name strong{
	margin-right: 10px;

	font-size: 24px;
	font-weight: 700;
	color: #7A7A7A;
}
.profile-item__name span{
	font-size: 18px;
	font-weight: 300;
	color: #7A7A7A;
}
.profile-item__career{
	font-size: 14px;
	font-weight: 400;
	line-height: 25px;
	letter-spacing: 0.2px;
	color: #5F5F5F;
}
.profile-item__career-sub{
	margin-top: -4px;
	margin-bottom: 5px;

	font-size: 12px;
	font-weight: 300;
	line-height: 16px;
	letter-spacing: 0.2px;
	color: #5F5F5F;
}



