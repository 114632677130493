.banner{
	position: relative;
	width: 100%;
	height: 100vh;
	/* background-image: url(../../img/main_banner.jpg); */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
}

.banner-cover{
	position: absolute;
	width: 100%;
	height: 100%;
	background: var(--color-dark-blue);
	opacity: .45;
}
.banner .container{
	position: relative;
	height: 100%;
	text-align: center;
}
.banner .container>.contents{
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
}
.main-banner__logo{
	max-width: 350px ;
	width: 100%;
}
.banner .container>.contents>img{
	margin-bottom: 30px;
}
.banner video{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	min-width: 100%;
	height: auto;
	min-height: 100%;
}
.banner .container>.contents>p{
	font-weight: 400;
	font-size: 26px;
	color: var(--color-white);
}
/* .banner .container>.contents>a{
	display: inline-block;
	padding: 20px 60px;
	margin-top: 60px;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 15px;
	border: solid 1px var(--color-white);
	transition: all .5s;

	color: var(--color-white);
	line-height: 30px;
} */
/* .banner .container>.contents>a>img{
	margin-right: 5px;
} */
/* .banner .container>.contents>a:hover{
	background: rgba(0, 0, 0, 0.7);
	box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.3);
} */
.banner__btn-wrap{
	display: flex;
	/* justify-content: space-between; */
	justify-content: flex-end;
	position: absolute;
	top: 130px;
	right:32px;
	width: 370px;
}
.banner__btn{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 170px;
	height: 45px;
	border-radius: 15px;
    background: rgba(0, 0, 0, 0.3);
	border: 1px solid #FDFDFF;
	padding: 10px 5px;


	color: #FDFDFF;
	text-align: center;
	font-weight: 600;
	font-size: 18px;
	line-height: 17px;
}

.banner .btn-scroll_down{
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	padding-bottom: 30px;
}
.banner .btn-scroll_down button{
	position: relative;
	font-size: var(--font-body1);
	letter-spacing: var(--ls-body1);
	font-weight: 400;
	color: var(--color-white);
}

.banner .btn-scroll_down button::after{
	content: "";
	position: absolute;
	bottom: -30px;
	left: 50%;
	transform: translateX(-50%);
	width: 1px;
	height: 30px;
	background: var(--color-white);
	cursor: default;
}

/* #viewWPBtn{
	height: 60px;
    width: 160px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 25%);
    border-radius: 12px;
    margin-top: 25px;
    font-family: Roboto;
    line-height: 60px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
	padding:0;
} */