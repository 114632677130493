
/* header S */
header[data-vi=true]{
	border-bottom: solid 1px var(--color-dark);
}
header[data-vi=true] .header-logo a img:nth-child(1){
	opacity: 0;
}
header[data-vi=true] .header-logo a img:nth-child(2){
	opacity: 1;
}
header[data-vi=true] nav ul li a{
	color: var(--color-dark);
}
header[data-vi=true] .wrap-btn-change_lang button{
	color:  var(--color-dark);
	border: solid 1px var(--color-dark);
}
header[data-vi=true] nav ul li:hover a::before{
	background: var(--color-dark);
}
/* header E */

#roadMap .container{
	padding-top: 200px;
	padding-bottom: 200px;
}

#roadMap h1{
	margin-bottom: 150px;

	text-align: center;
	font-size: var(--font-h1);
	letter-spacing: var(--ls-h1);
}

#roadMap .contents{
	position: relative;
	width: 100%;
}
#roadMap .contents .line{
	position: absolute;
	top: 0;
	left: calc(40% - 2px );
	border-left: solid 2px var(--color-dark-blue); 
	height: 100%;

}
#roadMap .contents .years{
	display: flex;
	flex-direction: column;
	width: 100%;
}

#roadMap .contents .years>li{
	position: relative;
	display: flex;
	margin-bottom: 100px;
}
#roadMap .years>li .left-quarter{
	position: relative;
	width: 40%;
	text-align: end;
	padding-right: 30px;

	font-size: var(--font-h3);
	color: var(--color-dark-blue);
	font-weight: 700;
	line-height: 25px;
}
#roadMap .years>li .left-quarter::after{
	content: '';
	position: absolute;
	right: -12px;
	top: 0;

	width: 24px;
	height: 24px;
	background: var(--color-white);
	border: solid 2px var(--color-dark-blue);
	border-radius: 100%;
	z-index: 1;

}

#roadMap .years>li .right-quarter-contents{
	width: 60%;
	padding-left: 60px;
}
#roadMap .years>li .right-quarter-contents li{
	list-style-type: disc;
	font-size: var(--font-body2);
	padding-bottom: 20px;
}